<template>
  <Page title="Log in">
    <form class="flex flex-col w-full sm:w-96 mx-auto">
      <Input
        field="username"
        inputclass="input--default"
        label="Email/username"
        required type="text"
        v-model="this.user.username"/>
      <Input
        @keyup.enter="handleLogin"
        field="password"
        inputclass="input--default"
        label="Password"
        required type="password"
        v-model="this.user.password"/>
      <Button
        class="max-w-xs mx-auto"
        @click="handleLogin"
        buttontype="background"
        type="button"
      >
        Log in
      </Button>

      <Button
        class="max-w-xs mx-auto text-primary"
        buttontype="text"
        @click="$router.push('/password-reset')"
      >
        Forgot password?
      </Button>
    </form>
  </Page>
</template>

<script>
import Page from '@/components/page.vue'
import Button from '@/components/button.vue'
import Input from '@/components/input.vue'
import { apiRequest } from '../components/js/utils'
import { mapState } from 'vuex'

export default {
  name: 'Login',
  components: { Page, Button, Input },
  computed: mapState({
    user: state => state.user.user
  }),
  methods: {
    async handleLogin () {
      await apiRequest.get('get-csrf/')
      try {
        await apiRequest.post('login/', {
          username: this.user.username,
          password: this.user.password
        })

        const user = this.user
        user.isAuthenticated = true
        user.password = null
        this.$store.commit('setUser', user)
        this.$store.commit('addAlert', {
          status: 'success',
          message: `You are logged in as ${user.username}`,
          timer: 3000
        })
        this.$router.push('/')
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.commit('addAlert', {
            status: 'warning',
            message: 'Invalid email/username or password',
            timer: 3000
          })
        } else {
          this.$store.commit('addAlert', {
            status: 'error',
            message: 'An error occured whilst attempting to log in. Please try again',
            timer: 3000
          })
        }
      }
    }
  },
  created () {
    if (this.user.isAuthenticated) {
      this.$router.push('/')
    } else {
      this.$store.dispatch('getUser').then(() => {
        if (this.user.isAuthenticated) {
          this.$router.push('/')
        }
      })
    }
  }
}
</script>
